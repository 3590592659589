/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import * as bootstrap from 'bootstrap';
require('./bootstrap');

window.Vue = require('vue');
window.Encoding = require('encoding-japanese');
window.bootstrap = bootstrap;
